<template>
  <div class="address-discovery">
    <v-container>
      <service-autodiscovery />
    </v-container>
  </div>
</template>
<script>
import ServiceAutodiscovery from "@/components/buttons/ServiceAutodiscovery.vue";
export default {
  name: "AddressDiscovery",
  components: {
    ServiceAutodiscovery
  }
};
</script>
